import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import MenuItem from "./MenuItem";
import { useNavigate } from "react-router-dom"; // not in use

const Navbar = React.memo(()=> {
  const [menuItemsData, setMenuItemsData] = useState();
  const [menuItemsData1, setMenuItemsData1] = useState();
  const [logindata, setlogindata] = useState(); // not in use
  const [loginpath, setloginpath] = useState();
  const [logintitle, setlogintitle] = useState();
  const [isActive, setIsActive] = useState(false);
  const [value, setValue] = useState("");
  const [ButtonDis, setButtonDis] = useState(true);
  const [navActive, setnavActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const API1 = `${process.env.REACT_APP_API_URL}/api/menu_items/dsci-top-header?format=json`;
    const API = `${process.env.REACT_APP_API_URL}/api/menu_items/dsci-menu?format=json`;
    const API_KEY = process.env.REACT_APP_API_KEY;

    const fetchApiData1 = async (url) => {
      try {
        const res = await fetch(url, {
          headers: {
            "api-key": API_KEY,
          },
        });
        const data = await res.json();

        setMenuItemsData1(data);
        setnavActive(true);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchApiData1(API1);
    const fetchApiData = async (url) => {
      try {
        const res = await fetch(url, {
          headers: {
            "api-key": API_KEY,
          },
        });
        const data = await res.json();
        setnavActive(true);
        setMenuItemsData(data);
      } catch (error) {
        console.log(error.response);
      }
    };
    fetchApiData(API);
  }, []);

  const handleSearchClick = () => {
    if (isActive === false) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };

  const styleButton = {
    height: "35px",
    marginTop: "0px",
    marginRight: "18px",
  };

  const handleSearch = (eevent) => {
    setButtonDis(true);
    if (value.length >= 3) {
      setButtonDis(false);
      setIsActive(false);
      navigate(`/search/${value}`);
      setValue("");
    }
  };

  const handleKeyDown = (event) => {
    setButtonDis(true);
    if (value.length >= 3) {
      setButtonDis(false);
    } else {
      setButtonDis(true);
    }
    if (event.key === "Enter") {
      setValue(event.target.value);
      if (value.length >= 3) {
        setIsActive(false);
        navigate(`/search/${value}`);
        setValue("");
      }
    }
  };

  const handleCheckChar = (ev) => {
    if (ev.target.value.length >= 3) {
      setButtonDis(false);
    } else {
      setButtonDis(true);
    }
  };

  //menuItemsData?.map((menu, index) => {});

  return (
    <>
       {navActive ? (
      
      <div className="navigation-wrapper">
        {/* top-header */}
        <Header menuItemsData={menuItemsData1} />
        {/* mobile header */}

        <div className="top-header d-none-2">
          <div className="container-fluid">
            <div className="container-main">
              <div className="row justify-content-center">
                <div className="col-2 col-md-3 ps-md-5">
                  <nav className="navbar navbar-expand-lg navbar-light bg-transs mobile-offcanvas">
                    <div className="container-fluid p-0">
                      <button
                        className="navbar-toggler "
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar2"
                        aria-controls="offcanvasNavbar2"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>

                      <div
                        className="offcanvas offcanvas-start "
                        tabIndex="-1"
                        id="offcanvasNavbar2"
                        aria-labelledby="offcanvasNavbar2Label"
                        aria-modal="true"
                        role="dialog"
                      >
                        <div className="offcanvas-header">
                          <button
                            type="button"
                            className="btn-close btn-close-white"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            {menuItemsData1?.map((menu, index) => {
                              return <MenuItem items={menu} key={index} />;
                            })}
                          </ul>
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            {menuItemsData?.map((menu, index) => {
                              return <MenuItem items={menu} key={index} />;
                            })}
                          </ul>
                          <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown d-block d-sm-block d-md-block">
                              <a
                                href=""
                                className=" nav-link dropdown-toggle"
                                role="button"
                                id="dropdownMenuLink"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i></i>

                                <span>Login</span>
                              </a>

                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href={loginpath}
                                    target="_blank"
                                  >
                                    {" "}
                                    <i></i>
                                    {logintitle}
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="col-8  col-md-6 text-center">
                  <Link className="navbar-brand" to="/">
                    <img
                      src={
                        process.env.REACT_APP_DOMAIN_BASE_URL +
                        "/files/logos/DSCI-White-1.svg"
                      }
                      alt="logo"
                      className="dsci-logo"
                    />
                  </Link>
                </div>
                <div className="col-2 col-md-3 pe-md-5 pe-3">
                  <Link className="float-end" to="/search">
                    <img
                      src={process.env.PUBLIC_URL + "/assests/img/search-1.png"}
                      className="me-8"
                      style={{ width: "29px", marginTop: "6px" }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* web header */}

        {/*Top-Header */}
        <header className="main-nav">
          <div className="container container-main">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                <nav className="navbar navbar-expand-lg navbar-light bg-transs">
                  <div className="container-fluid p-0">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
                        {menuItemsData?.map((menu, index) => {
                          return <MenuItem items={menu} key={index} />;
                        })}
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
       ) : ''}
    </>
  );
});

export default Navbar;
