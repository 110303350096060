import React, { Suspense, Lazy, useEffect, useState } from "react";
import Navbar from "./Navigations/Navbar/Navbar";
import Footer from "./Navigations/Footer";
import Loader from "./components/Loader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import Microsite from "./pages/Microsite/Microsite";
import api, { _GETConfig } from "./api"

const BlogInsidePage = React.lazy(()=>import("./pages/blogs/BlogDetails"));
const Blogs = React.lazy(()=>import("./pages/blogs/BlogList"));
const Podcast = React.lazy(()=> import("./pages/dsci-podcast"));
const Career = React.lazy(() => import("./pages/Career/Career"));
const Career_Details = React.lazy(() =>
  import("./pages/Career/Career_Details/Career_Details")
);
const AISS = React.lazy(() => import("./pages/Events/AISS"));
const DsciEvents = React.lazy(() => import("./pages/Events/DsciEvents"));
const Member_Directory = React.lazy(() =>
  import("./pages/Corporate Members/Member_Directory")
);
const Whatnew = React.lazy(() => import("./pages/Whatnew/Whatnew"));
const WhatnewDetail = React.lazy(() => import("./pages/Whatnew/WhatnewDetail"));
const Media_Coverage = React.lazy(() =>
  import("./pages/Media-Centre/Media_Coverage")
);

// Forms
const JoinChapter = React.lazy(() => import("./pages/Forms.js/JoinChapter"));
const ChangeChapter = React.lazy(() =>
  import("./pages/Forms.js/ChangeChapter")
);
const DCPPForm = React.lazy(() => import("./pages/Forms.js/DCPPForm"));
const DCPLAForm = React.lazy(() => import("./pages/Forms.js/DCPLAForm"));
const ReportForm = React.lazy(() => import("./pages/Forms.js/ReportForm"));
const Response = React.lazy(() => import("./pages/Others/Response"));
const ThankYou = React.lazy(() => import("./pages/Forms.js/ThankYou"));
const CyberShikshaForm = React.lazy(() =>
  import("./pages/Forms.js/CyberShikshaForm")
);
const PrivacyModule = React.lazy(() =>
  import("./pages/Forms.js/PrivacyModule")
);

const MemberDetail = React.lazy(() =>
  import("./pages/Corporate Members/MemberDetail")
);
const Resource_Centre = React.lazy(() =>
  import("./pages/Resource_Centre/Resource_Centre")
);
const ReportDetail = React.lazy(() =>
  import("./pages/Resource_Centre/ReportDetail")
);
const Advisory = React.lazy(() => import("./pages/Advisory/Advisory"));
const SearchResults = React.lazy(() =>
  import("./pages/General Pages/SearchResults")
);

const Home = React.lazy(() => import("./pages/HomePage/Home"));
const PageTemplate = React.lazy(() =>
  import("./pages/Page Template/PageTemplate")
);
const EventDetail = React.lazy(() => import("./pages/Events/EventDetail"));
const AdvisoryDetail = React.lazy(() =>
  import("./pages/Advisory/AdvisoryDetail")
);
const NotFound = React.lazy(() => import("./pages/General Pages/NotFound"));

function App() {
  const [bData, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [maintainance, setMaintainance] = useState(false);
    let alias = window.location.href.replace(process.env.REACT_APP_ENVIRONVENT_URL, "");
  let hash = alias.split("#")[0];
  if (undefined !== hash && hash.length > 1) {
    alias = hash;
  }
  let ques = alias.split("?")[0];
  if (undefined !== ques && ques.length > 1) {
    alias = ques;
  }
     const fetchData = async (url) => {
    setLoader(true);
    var config = _GETConfig(process.env.REACT_APP_MAINTAINANCE_API_KEY);
    //console.log("config", config)
      const res=  await fetch(url,config)
            const response = await res.json();
        if (response?.data) {
                    setLoader(false);
          if (response?.data?.maintenance_mode === 1) setMaintainance(true);
        }         
  };
  useEffect(() => {
    fetchData(api.maintainance_mode);
  }, []);

  return (
    <>
      {!maintainance ? (
        <BrowserRouter>
          <Navbar /> 
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Home />
                  </Suspense>
                }
              />
             
              <Route
                exact
                path={`/content${alias}`}
                element={
                  <Suspense fallback={<Loader />}>
                    <PageTemplate endpoint={`${alias}`} />
                  </Suspense>
                }
              />
              
              <Route
                exact
                path={`/content${alias}:id`}
                element={
                  <Suspense fallback={<Loader />}>
                    <PageTemplate endpoint={`${alias}`} />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/cyber-shikshaa-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <CyberShikshaForm />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/privacy-module-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <PrivacyModule />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/search"
                element={
                  <Suspense fallback={<Loader />}>
                    <SearchResults />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/search:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <SearchResults />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/position-openings"
                element={
                  <Suspense fallback={<Loader />}>
                    <Career />
                  </Suspense>
                }
              />

              <Route
                exact
                path="resource/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <ReportDetail />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/advisory"
                element={
                  <Suspense fallback={<Loader />}>
                    <Advisory />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/advisory/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Advisory />
                  </Suspense>
                }
              />
              <Route
                exact
                path="advisory/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <AdvisoryDetail />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/corporate-member/member-directory"
                element={
                  <Suspense fallback={<Loader />}>
                    <Member_Directory />
                  </Suspense>
                }
              />
              
              <Route
                exact
                path="position-openings/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Career_Details />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/latest-at-dsci"
                element={
                  <Suspense fallback={<Loader />}>
                    <Whatnew />
                  </Suspense>
                }
              />
              <Route
                exact
                path="latest-at-dsci/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <WhatnewDetail />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/media-centre"
                element={
                  <Suspense fallback={<Loader />}>
                    <Media_Coverage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/media-centre/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Media_Coverage />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/chapter-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <JoinChapter />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/chapter-change"
                element={
                  <Suspense fallback={<Loader />}>
                    <ChangeChapter />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/dcpp-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <DCPPForm />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/dcpla-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <DCPLAForm />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/response"
                element={
                  <Suspense fallback={<Loader />}>
                    <Response />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/thankyou"
                element={
                  <Suspense fallback={<Loader />}>
                    <ThankYou />
                  </Suspense>
                }
              />
              <Route
                exact
                path={`/generalpayment/:nid`}
                element={
                  <Suspense fallback={<Loader />}>
                    <ReportForm />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/events"
                element={
                  <Suspense fallback={<Loader />}>
                    <DsciEvents />
                  </Suspense>
                }
              />
              <Route
                exact
                path="events/:pathname"
                element={
                  <Suspense fallback={<Loader />}>
                    <AISS />
                  </Suspense>
                }
              />
              <Route
                exact
                path="events/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <EventDetail />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/knowledge-center/annual-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/event-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/newsletters"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/pov"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/study-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/whitepapers"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/dsci-podcast"
                element={
                  <Suspense fallback={<Loader />}>
                    <Podcast />
                  </Suspense>
                }
              />

                           <Route
                exact
                path="/dsci-blogs"
                element={
                  <Suspense fallback={<Loader />}>
                    <Blogs />
                  </Suspense>
                }
              />
               <Route
                exact
                path="/dsci-blog/content/:pathname"
                element={
                  <Suspense fallback={<Loader />}>
                    <BlogInsidePage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="*"
                element={
                  <Suspense fallback={<Loader />}>
                    <NotFound />
                  </Suspense>
                }
              />
            </Routes>
          </Suspense>
          <Footer />
        </BrowserRouter>
      ) : (
        <div className="text-center mt-5 p-5">
          <h1>Site is under maintainance, we will get back shortly</h1>
        </div>
      )}
    </>
  );
}

export default App;
